
import { mapState } from 'vuex'
import get from 'lodash/get'

export const menus = [
  // {
  //   key: 'discover',
  //   text: 'discover.title',
  //   url: '/discover',
  //   dataTestId: 'nav-menu-discover-link',
  // },
  // {
  //   key: 'event',
  //   text: 'events',
  //   url: '/events',
  // },
  {
    key: 'boost',
    text: 'boost',
    url: '/boost',
    dataTestId: 'nav-menu-boost-link',
  },
  {
    key: 'creatorgrant',
    text: 'creatorGrant.title',
    url: '/creator-grant',
    dataTestId: 'nav-menu-creatorgrant-link',
  },
  // {
  //   key: 'blog',
  //   text: 'blog',
  //   url: 'https://ganknow.com/blog/',
  //   dataTestId: 'nav-menu-blog-link',
  //   type: 'href',
  // },
  {
    key: 'creatorUniversity',
    text: 'creatorUniversity',
    url: 'https://learn.ganknow.com/',
    tracker: ['university_link_topnav', 'university_link'],
    dataTestId: 'nav-menu-creatoruniversity-link',
    type: 'href',
  },
]

export default {
  data() {
    return {
      menus,
      creatorDropdown: false,
    }
  },
  computed: {
    ...mapState({
      locale: state => state.settings.locale,
    }),
  },
  methods: {
    isShowDark() {
      if (this.$route.name !== 'nickname') return true
      if (this.$route.name === 'nickname' && this.$colorMode.preference === 'dark') return true
      return false
    },
    redirectBlog() {
      window.location.assign('/blog')
    },
    onClickTrackEvent(eventName) {
      if (!eventName) {
        console.warn('No event name provided for tracking.')
        return
      }
      const pushEvent = name => {
        this.$gtm.push({
          event: name,
          user_id: get(this.$auth, 'user.id') || '',
        })
      }

      if (typeof eventName === 'object') {
        eventName.forEach(pushEvent)
      } else {
        pushEvent(eventName)
      }
    },
  },
}
